<template>
  <el-main class="rh-table">
    <h1>Contrats</h1>
    <app-table
      tablenom="contrats"
      :cols="colonnes"
      :items="contrats"
      :loadstatus="contratsLoadStatus"
      :deletestatus="contratDeleteStatus"
      :formroutes="contFormRoutes"
      :actions="contActions"
    />
  </el-main>
</template>
<script>
import AppTable from "@/components/rh/ui/table/AppTable";

export default {
  components: { "app-table": AppTable },
  data() {
    return {
      contFormRoutes: {
        edit: { route: "contratedit", params: { idcont: "id" } },
        create: { route: "contratcreate" },
      },
    };
  },
  computed: {
    contratsLoadStatus() {
      // 1 = loading, 2 = succès, 3 = échec du chargement des données
      return this.$store.state.contl.contratsLoadStatus;
    },
    contratDeleteStatus() {
      // 1 = deleting, 2 = succès, 3 = échec
      return this.$store.state.contl.contratDeleteStatus;
    },
    contrats() {
      return this.$store.state.contl.contrats;
    },
    colonnes() {
      return this.$store.state.contl.colonnes;
    },
    contActions() {
      return this.$store.state.contl.actions;
    },
  },
};
</script>
